@value teal, lighter-grey, lighter-teal, lightest-teal, tableGridGrey from '../../Assets/styles/variables/colors.css';

.tableContainer {
  margin-top: 20px;
  height: calc(100vh - 160px) !important;
}
.tableRowHeading {
  border-bottom: 1px solid tableGridGrey;
  font-size: 16px; 
  font-family:'SF' Compact Text;
}
.tableRowHeading > div {
  padding: 30px 15px 15px !important;
  font-weight: bold;
  text-transform: capitalize;
}
.selectedRow {
  font-weight: bold;
  background-color: lighter-teal;
}
.tableBody .listRow:hover {
  background-color: lighter-teal;
}
.tableBody .listRow {
  height: 55px;
}
.tableBody .listRow .listRowItem:first-child {
  font-size: 12px;
  padding: 8px 15px;
  display: flex;
  align-items: center;
  height: inherit;
}
.listRowItem > div:first-child {
  width: 85%;
  text-transform: capitalize;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tableBody .listRow:last-child {
  border-bottom: 1px solid tableGridGrey;
}
.notFound {
  display: flex;
  justify-content: center;
  margin: 20px 0px;
}
.count {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  color: white;
  background-color: teal;
  font-size: 12px;
  margin-left: 10px;
  line-height: 0px;
}
