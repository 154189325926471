@value medium-grey, forgot-pass-blue, darker-grey, black from '../../Assets/styles/variables/colors.css';

.pageUpdated {
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: baseline;
  right: 35px;
}
.pageUpdatedHigh {
  composes: pageUpdated;
  bottom: 18px;
}
.pageUpdatedLow {
  composes: pageUpdated;
}
.pageUpdated p {
  font-size: 12px;
  color: darker-grey;
  text-transform: uppercase;
  font-weight: 500;
  margin-right: 14px;
  font-family: 'Work Sans';
  line-height: 20px;
  letter-spacing: -0.2px;
}
.updatedTime {
  font-size: 18px;
  font-weight: 800;
  color: black;
  margin-right: 14px;
}
.updatedTime span {
  font-size: 14px;
  color: darker-grey;
  font-weight: 600;
  margin-left: 1px;
}
.refreshPage {
  border: none;
  outline: none;
  background-color: transparent;
  height: 20px;
  width: 16px;
  padding: 0;
  margin: auto;
}
.refreshPage:hover:disabled  {
  cursor: not-allowed;
}
.refreshPage:disabled svg path {
  fill: medium-grey;
}
.refreshPage:hover {
  cursor: pointer;
}
.refreshPage svg {
  height: 20px;
  width: 20px;
}
.refreshPage svg path {
  fill: black;
}
@keyframes spin {
  to { transform: rotate(1turn); }
}
.spinningRefresh {
  animation: spin 850ms infinite;
  animation-timing-function: linear;
  width: 16px;
  height: 20px;
}
