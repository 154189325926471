@value teal, lighter-teal from '../../Assets/styles/variables/colors.css';

.chatBody {
  overflow-y: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}
.chatBody::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.message {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 16px;
  font-size: 12px;
}
.messageLeft {
  composes: message;
  flex-direction: row;
}

.messageRight {
  composes: message;
  flex-direction: row-reverse;
}

.messageContent {
  border-radius: 100px;
  padding: 5px 15px;
  max-width: 500px;
  background-color: lighter-teal;
  border: 1px solid lighter-teal;
  margin: 0 !important;
  word-wrap: break-word;
  font-weight: normal;
}

.messageTimestamp {
  bottom: 5px;
}

.messageTimestampRight {
  composes: messageTimestamp;
}

.messageTimestampLeft {
  composes: messageTimestamp;
}
.inputFieldContainer {
  display: flex;
  border-radius: 2em;
  /* padding: 5px; */
  background-color: #dcf0ee;
}
.messageHeader {
  text-align: center;
  margin: 26px auto;
}

.sendButton {
  color: white !important;
  /* background-color: teal !important;
  border-radius: 2em !important; */
  padding: 10px !important;
}

.imgBox {
  line-height: 0;
  max-height: 336px;
  max-width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}

.imgBox > img {
  background-color: #2021240a;
  padding: 18px;
  border-radius: 4px;
  background-size: 0;
  box-sizing: border-box;
  display: inline-block;
  max-height: 336px;
  max-width: 100%;
  text-indent: -9999px;
}
