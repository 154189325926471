@value darker-grey, dark-white, light-grey, white, black, bg-white from '../../Assets/styles/variables/colors.css';
@value contentWidth, mainFontFamily  from '../../Assets/styles/variables/global.css';
@value h2, h3  from '../../Assets/styles/typography.css';

/* Layout css */
.pageContainer {
  background-color: bg-white;
  max-width: contentWidth;
  min-width: contentWidth;
  margin: 20px auto;
  padding: 0 20px;
}
.contentWrapper {
  border-radius: 4px;
  box-shadow: 0px 2px 3px 0px light-grey;
  background-color: white;
  position: relative;
}

/* Patient form css */
.formWrapper {
  composes: contentWrapper;
  background-color: white;
  overflow-y: scroll;
}
.formTop {
  padding: 0 30px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.formTitle {
  composes: h2;
  font-weight: 600;
  font-size: 21px;
  /* margin: 0 0 13px 25px; */
  padding-top: 24px;
}
.formSubtitle {
  composes: h3;
  /* margin: 0 0 13px 25px; */
  font-size: 15px;
  text-transform: none;
}

.settings {
  composes: pageContainer;
}

.revokeSection {
  margin-bottom: 24px;
}
