@value popoverBorder, popoverShadow, white, teal from '../../Assets/styles/variables/colors.css';
@value h3 from '../../Assets/styles/typography.css';

.popover {
  position: absolute;
  background-color: white;
  border-radius: 6px;
  border: 1px solid popoverBorder;
  box-shadow: 0px 4px 10px 0px popoverShadow;
}

.label {
  composes: h3;
  text-align: left;
  color: teal !important;
  font-size: 12px;
  padding: 0px !important;
  margin: 0px !important;
  font-family: 'Work Sans' !important;
  text-transform: unset !important;
}