@value grey-3, teal, light-grey from '../../Assets/styles/variables/colors.css';
@value mainFontFamily from '../../Assets/styles/variables/global.css';

.searchInput {
  padding: 0 8px;
  color: grey-3;
  min-height: 40px;
  min-width: 100%;
  box-shadow: 0 1px 2px 0 light-grey;
  border-radius: 4px;
  box-sizing: border-box;
  outline: 0;
  border: 1px solid teal;
  font-size: 14px;
  font-weight: normal;
  font-family: mainFontFamily;
}

.searchInput::placeholder {
  color: hsl(0, 0, 50%);
}
.closeBtn {
  padding: 8px !important;
  height: 40px;
  background: transparent !important;
  position: absolute !important;
  right: 0px;
}
.closeBtn svg {
  width: 20px;
}
