@value black, kardia-red, white, dark-white, slate-grey, silver, lighter-grey, lightest-grey, light-grey, medium-grey, darker-grey, kardia-black, lightest-teal, lighter-teal, light-teal, teal from '../../Assets/styles/variables/colors.css';
@value h3, lbl from '../../Assets/styles/typography.css';
@value newDefaultFormInput from '../../Assets/styles/formElements.css';
@value formSection, formSectionRow, formTitle, formSubtitle, placeholder, submitWrapper, buttonWrapper, submit from '../../Assets/styles/patientForm.css';
@value popover from '../Popovers/Popovers.module.css';

.inputGroup {
  display: flex;
  flex-direction: column;
  position: relative;
}
.inputGroup textarea {
  resize: none;
  height: 148px;
  white-space: normal;
}
.inputRowGroup {
  display: flex;
  flex-direction: row;
}
.inputRowGroup .input {
  margin-left: 8px;
  min-width: 100px;
  width: 100px;
}

.label {
  composes: h3;
  /* padding: 0 0 12px 16px; */
  padding: 6px 0px 0px 8px;
  color: black !important;
  font-size: 12px;
  text-transform: capitalize;
  font-family: 'Work Sans' !important;
}
.rightIconBtn {
  padding: 8px !important;
  height: 100%;
  background: transparent !important;
  position: absolute !important;
  right: 0px;
}
.rightIconBtn svg {
  width: 16px;
  color: black;
}

.required {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  position: absolute;
  /* top: 2px;
  left: 2px;
  background-color: kardia-red; */
  top: 0px;
  left: -2px;
  color: kardia-red;
  font-size: 20px;
}
.input {
  composes: newDefaultFormInput;
  height: 32px;
}
.multiBox .react-select-form-selector .react-select-form-selector__control {
  height: auto;
}
.editPlaceholder {
  width: 276px;
}
.editPatientSection {
  composes: formSection;
}
.editPatientSection .label {
  color: silver;
  padding-left: 8px;
  padding-top: 6px;
  padding-bottom: 0px;
  user-select: none;
}
.editPatientSection .required {
  /* top: 8px;
  left: -6px; */
}
.editEnrollmentSection,
.editMemberSection {
  composes: editPatientSection;
}

.recordingGroup,
.editLabelGroup {
  display: flex;
  flex-direction: row;
  width: 276px;
  flex-wrap: wrap;
}
.editLabelGroupFull {
  composes: editLabelGroup;
  width: 100%;
}
.editLabelGroupFull .inputGroup {
  width: calc(100% - 36px);
}
.editIcon {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: lighter-teal;
  margin-right: 12px;
}
.editIcon svg {
  height: 14px;
  width: 14px;
  margin: 5px;
  fill: teal;
}
/*.editIcon svg path {*/
/*  fill: transparent;*/
/*}*/
.editIcon:hover {
  cursor: pointer;
}
.editIconActive {
  composes: editIcon;
  background-color: teal;
}
.editIconActive svg {
  fill: lighter-teal;
}
.noEditIcon {
  composes: editIcon;
  background-color: transparent;
}
.noEditIcon:hover {
  cursor: none;
}
.checkboxWrapper {
  display: inline-block;
}
.checkboxGroup {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  height: 100%;
  user-select: none;
}
.checkboxGroupFixedWidth {
  composes: checkboxGroup;
  width: 290px;
}
.checkboxIcon {
  height: 20px;
  width: 20px;
}
.disabledCheckboxIcon {
  composes: checkboxIcon;
}
.disabledCheckboxIcon rect {
  fill: transparent;
  stroke: light-grey;
  stroke-width: 1px;
}
.disabledCheckboxIcon path {
  fill: medium-grey;
}
.checkboxIconChecked {
  height: 20px;
  width: 20px;
  stroke: teal;
  fill: light-teal;
}
.checkboxLabel {
  composes: label;
  margin-left: 8px;
  font-weight: normal;
  margin-top: -3px;
  font-size: 14px;
  text-transform: none;
  color: silver;
}
.checkbox input[type='checkbox'] {
  display: none;
}

.toggleSection {
  display: flex;
  justify-content: space-between;
}

.businessTime {
  display: flex;
}

.toggleInfo {
  font-weight: 600;
  font-size: 16px;
  font-family: 'Work Sans';
}

.days {
  font-weight: 600;
  font-size: 16px;
  margin-top: 40px;
  margin-bottom: -10px;
  font-family: 'Work Sans';
}

.dnd {
  font-weight: 500;
  font-size: 16px;
  font-family: 'Work Sans';
}

.notificationWarning {
  font-weight: 200;
  font-size: 14px;
  font-family: 'Work Sans';
  border-bottom: 2px lightgrey solid;
}

.time {
  font-weight: 200;
  font-size: 16px;
}

.smallInput {
  width: 100px;
}
.error {
  composes: lbl;
  color: kardia-red;
  padding: 8px 0 0 8px;
}
.prescribeContainer {
  width: 40%;
  margin: 52px auto 44px;
}
.prescribeBorder {
  background-color: lightest-teal;
  border: 1px solid medium-grey;
  border-radius: 6px;
  padding: 30px 20px;
}
.prescribeSection {
  position: relative;
}
.prescribeSection h2 {
  font-size: 21px;
  margin: 36px 0 11px;
}
.prescribeSection .label {
  padding: 0;
}
.prescribeSection > .label {
  color: silver;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 16px;
}
.prescribeSection .prescribeBorder > label > div,
.prescribeSection .prescribeBorder > label > input {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
}
.prescribeSection label {
  margin-top: 20px;
}
.prescribeSection label:first-of-type {
  margin-top: 0px;
}
.prescribeSectionGrey {
  position: relative;
  pointer-events: none;
  opacity: 0.4;
}
.prescribeSectionGrey h2 {
  font-size: 21px;
  margin: 36px 0 11px;
}
.prescribeSectionGrey .label {
  padding: 0;
}
.prescribeSectionGrey > .label {
  color: silver;
  font-size: 14px;
  font-weight: 500;
  padding-bottom: 16px;
}
.prescribeSectionGrey .prescribeBorder > label > div,
.prescribeSectionGrey .prescribeBorder > label > input {
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.05);
}
.prescribeSectionGrey label {
  margin-top: 20px;
}
.prescribeSectionGrey label:first-of-type {
  margin-top: 0px;
}
.prescribeStep {
  position: absolute;
  left: -113px;
  top: 76px;
}
.prescribeStep svg {
  width: 105px;
  height: 66px;
}
.prescribeStep span {
  text-transform: uppercase;
  color: medium-grey;
  font-size: 14px;
  font-weight: 900;
  position: absolute;
  top: 29.5px;
  left: 2px;
}
.prescribeSubmitWrapper {
  composes: submitWrapper;
}
.enrollmentSubmitWrapper {
  composes: submitWrapper;
  position: relative;
}
.enrollmentSubmitWrapper .buttonWrapper {
  display: flex;
  flex-direction: row;
}
.enrollmentSubmitWrapper .buttonWrapper button:first-of-type {
  margin-right: 5px;
}
.enrollmentSubmitWrapper .buttonWrapper button:last-of-type {
  margin-left: 5px;
}
.editMemberSubmitWrapper {
  composes: enrollmentSubmitWrapper;
  justify-content: flex-start;
  position: relative;
}
.prescribeSubmitWrapper button svg rect {
  fill: light-teal;
}
.prescribeSubmitWrapper button:hover svg rect {
  fill: teal;
}
.prescribeSubmitWrapper button svg path {
  fill: white;
}
.prescribeSubmitWrapper button:disabled svg rect {
  fill: medium-grey;
  stroke: medium-grey;
}
.prescribeSubmitWrapper button:disabled svg g {
  stroke: white;
}
.deleteCoach {
  margin-right: 30px !important;
}
.cancel,
.submitLoading {
  composes: submit;
  width: 177px;
}
.cancel {
  background-color: lighter-teal;
  border-color: lighter-teal;
  color: teal;
}
.cancel:hover {
  color: white;
}
.deleteCoach svg,
.submit svg,
.cancel svg {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.cancel svg {
  fill: silver;
}
.cancel:hover svg {
  fill: white;
}
.submit svg {
  fill: white;
}
.submit:disabled svg {
  fill: silver;
}
.submitLoading:disabled,
.submitLoading:disabled:hover {
  background-color: teal;
  opacity: 1;
  border-color: teal;
  padding: 10px 38px;
  height: 42px;
}
.submitLoading svg path {
  stroke: white;
}
.prescribeSubmitWrapper .submitLoading:disabled svg path {
  fill: transparent;
}

.customRules {
  margin-left: 10px;
}
.customRules h3 {
  padding: 16px 0 12px 8px;
  color: silver;
  font-weight: bold;
  font-size: 12px;
}
.customRules input:disabled {
  border: 1px solid lightest-grey;
  color: silver;
  background-color: white;
  font-weight: normal;
}
.customRules .label {
  padding: 6px 0 6px 8px;
}
.customRules > div,
.customRules > label {
  margin: 0 0 4px;
  height: 32px;
}
.editLabelGroup .customRules {
  margin: 10px 0 0 36px;
}
.recordingGroup .customRules label,
.editLabelGroup .customRules label {
  width: auto;
}
.recordingGroup .customRules .label,
.editLabelGroup .customRules .label {
  width: 200px;
}
.emailRules {
  composes: customRules;
}
.emailRulesLabel {
  composes: label;
  margin-top: 20px !important;
}
.emailRulesLabelFirst {
  composes: label;
  margin-top: 10px !important;
}
.editLabelGroup .emailRules .label {
  width: 250px;
}
.fakeInput {
  font-size: 14px;
  font-weight: bold;
  padding: 8px;
  width: 240px;
  word-wrap: break-word;
}
.fakeInputIndication {
  font-size: 14px;
  font-weight: bold;
  padding-left: 8px;
  width: 400px;
  word-wrap: break-word;
}
.checkboxWithDesc .checkboxLabel {
  padding: 0;
  color: black;
  font-weight: 600;
}
.checkboxWithDesc .description {
  font-size: 13px;
  margin-top: 8px;
  color: silver;
  font-family: 'Work Sans';
}
/* popover styles mixed from popover and dropover for confirm ecg */
.dropover {
  composes: popover;
  bottom: 60px;
  left: 0px;
  width: 260px;
}
.dropover::after {
  position: absolute;
  bottom: -0.7em;
  /* right: 20%; */
  left: 45%;
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
}
.dropoverContent {
  padding: 11px 16px;
  text-align: center;
}
.dropoverContent h2 {
  font-size: unset;
  font-weight: bold;
  margin-bottom: 8px;
}
.dropoverControls {
  border-top: 1px solid light-grey;
  display: flex;
  flex-direction: row;
  justify-self: flex-end;
}
.dropoverControls > * {
  flex: 1;
  display: flex;
  padding: 8px 11px;
  justify-content: center;
}
.dropoverControls > *:first-child {
  border-right: 1px solid light-grey;
}
.dropoverControls a {
  color: teal;
}
.formSectionRowStacked {
  composes: formSectionRow;
  display: block;
}
.formSectionRowStacked label {
  margin-bottom: 14px;
}
.formSectionRowStacked label:last-of-type {
  margin-bottom: 14px;
}

.formSectionRowGrid {
  composes: formSectionRow;
  display: grid;
  column-gap: 20px;
  row-gap: 20px;
}
.c4 {
  grid-template-columns: repeat(4, 1fr);
}
.c2 {
  grid-template-columns: repeat(2, 1fr);
}

.validationRules {
  margin: 35px 0 0 0;
}
.updatePwWrapper {
  composes: submitWrapper;
  justify-content: flex-start;
  margin-top: 28px;
}
.pwValidationError {
  color: kardia-red;
  text-align: center;
}

/* hide number input arrows */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

.printIcon {
  margin-right: 10px;
}

.reportFormSection {
  background-color: #edf7f6;
  border-radius: 8px;
  border: 1px solid lighter-grey;
  margin-bottom: 8px;
  border-bottom: 0px;
  height: 96px;
}
.reportFormSection label {
  position: relative;
  width: 240px;
}
.reportFormSection > label {
  width: 100%;
}
.reportFormSectionRow {
  margin-top: 32px;
  display: flex;
}
.viewReport {
  composes: submit;
  width: 110px;
  height: 35px;
  background-color: teal;
  margin-bottom: 40px;
  margin-right: 70px;
  padding: 0px;
}

.pendingReport {
  composes: submit;
  width: 110px;
  height: 25px;
  background-color: teal;
}

.appointmentText {
  font-size: 13px;
  margin-top: 8px;
  margin-bottom: 8px;
  color: silver;
  font-family: 'Work Sans';
}

.appointmentbuttonWrapper {
  composes: buttonWrapper;
  display: flex;
  flex-direction: row;
}

.businessHoursScroll {
  max-height: 700px;
  overflow: auto;
}
.manageAvail {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #babfbd;
  padding-top: 10px;
}
.editDropdown {
  display: flex;
  width: 106%;
  justify-content: space-between;
}

.editDropdownLast {
  width: 106%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.editDropdownLastMyInfo {
  width: 115%;
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.buttonVisible {
  visibility: hidden;
}
.buttonVisibleMyinfo {
  display: none !important;
}

.buttonCss {
  /* margin-top: 35px !important; */
  text-decoration: underline !important;
  color: #142a39 !important;
}
.labelColorDropdown {
  margin-bottom: 6px !important;
  margin-top: 6px !important;
  text-transform: capitalize;
  font-family: 'Work Sans' !important;
  font-size: 12px !important;
  color: black !important;
}
.selectFieldStyle {
  composes: newDefaultFormInput;
  height: 32px;
  /* font-size: 14px !important; */
  /* font-weight: 500 !important; */
  font-family: proxima-nova, 'Helvetica Neue', helvetica, arial, sans-serif !important;
}
.selectFieldStyle fieldset {
  border: none;
}
.button {
  text-transform: none !important;
}
.disableIconCss {
  cursor: no-drop !important;
}
.displayNone {
  display: none;
}

.formSubHeader {
  composes: formTitle;
  margin: 0 0 13px 0;
  font-weight: 500;
  font-family: 'Work Sans' !important;
}
.inviteSubmitWrapper {
  composes: submitWrapper;
  justify-content: flex-start;
}
