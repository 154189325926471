@value white from '../../Assets/styles/variables/colors.css';
@value mainFontFamily from '../../Assets/styles/variables/global.css';
@value submit from '../../Assets/styles/patientForm.css';

.NotFoundPage {
  text-align: center;
  background-image: url('../../Assets/images/404-bg.png');
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.NotFoundPage h3 {
  font-size: 100px;
  font-weight: 800;
  margin-bottom: 50px;
  padding-top: 150px;
  text-transform: capitalize;
}
.NotFoundPage h2 {
  font-size: 32px;
  font-family: mainFontFamily;
  margin-bottom: 14px;
  font-weight: bold;
}
.NotFoundPage p {
  font-size: 20px;
  line-height: 40px;
}
.dashboardBtn {
  composes: submit;
  margin: 27px auto 0;
  padding: 11px 23px;
}
.dashboardBtn svg {
  fill: white;
  height: 20px;
  width: 20px;
  margin-top: -5px;
  margin-right: 10px;
}
