@value white, teal, forgot-pass-blue, silver, black, lightest-teal, dark-blue, kardia-orange, kardia-red from '../../Assets/styles/variables/colors.css';
@value newDefaultFormInput from '../../Assets/styles/formElements.css';
@value submit from '../../Assets/styles/patientForm.css';
@value h1 from '../../Assets/styles/typography.css';
@value hTwoFontSize, mainFontFamily from '../../Assets/styles/variables/global.css';

.login {
  background-color: #e5f2fc; /* color to match image */
  background-image: url(../../Assets/images/login-bg.jpg);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  /*display: flex;*/
  /*flex-direction: column;*/
  /*align-content: space-between;*/
  min-height: 100vh;
  /*overflow: hidden;*/
  /*justify-content: center;*/
  display: table;
  position: absolute;
  height: 100%;
  width: 100%;
}
.loginOuterCont {
  display: table-cell;
  vertical-align: middle;
}
.requestNewPassword, .resetPassword, .ssoLogin, .teamMemberInvite {
  composes: login;
  /*background-image: none;*/
}
.loginBox {
  width: 790px;
  margin: 0 auto;
  background-image: linear-gradient(-233deg, rgba(250, 250, 252, 0.9) 0%, rgba(242, 244, 248, 0.9) 100%);
  border-radius: 4px;
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.2);
}
.header {
  flex: 1;
  padding: 39px 16px 49px;
}
.logo {
  display: flex;
  justify-content: center;
}
.logo path {
  fill: black;
}
.logo svg {
  height: 39px;
  width: 216px;
  min-width: 324px;
}
.lr {
  composes: h1;
  font-size: 14px;
  color: silver;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  width: 232px;
  margin: 23px auto 0;
}
.lr span {
  display: inline-block;
}
.preamble {
  margin-bottom: 35px;
}
.preamble h4 {
  color: silver;
  font-size: 20px;
  margin-bottom: 20px;
}
.preamble p {
  color: silver;
  font-size: 16px;
  margin-bottom: 0;
}
.preambleSuccess {
  composes: preamble;
  margin-bottom: 0;
}
.preambleSuccess svg {
  width: 60px;
  height: 60px;
  fill: teal;
  margin-bottom: 20px;
}
.preambleSuccess h4 {
  font-size: 17px;
}
.preambleSso {
  composes: preamble;
}
.preambleSso svg {
  width: 60px;
  height: 60px;
}
.preambleSso svg g {
  stroke-width: 1;
}
.content {
  flex: 10;
  display: flex;
  justify-content: center;
  align-content: center;
  padding-bottom: 67px;
}
.contentInner {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: flex-start;
  max-width: 330px;
  width: 330px;
}
.inputField {
  margin: 10px 0;
}
.defaultTextInput {
  composes: newDefaultFormInput;
  padding: 9px 16px; /* Reselect in thirdPartyCssOverrides */
  width: 100%;
}
.disabledButton {
  composes: submit;
  position: relative;
  cursor: not-allowed;
  margin: 20px auto;
  width: 140px;
  height: 41px;
  padding: 9px 38px;
}
.disabledButton:hover {
  background-color: teal;
  border-color: teal;
}
.signInButton {
  composes: submit;
  cursor: pointer;
  position: relative;
  margin: 20px auto;
  letter-spacing: 1px;
  width: 140px;
  padding: 11px 0;
}
.signInButton svg {
  margin-right: 8px;
  width: 20px;
  height: 20px;
  fill: white;
}
.signUpButton {
  composes: signInButton;
  width: 200px;
}
.signInLink {
  composes: signInButton;
}
.signInLink:hover {
  color: white;
}
.errorMsg {
  margin: 9px 0;
  background-color: kardia-orange;
  color: white;
  border: 1px solid kardia-red;
  border-radius: 4px;
  font-size: hTwofontSize;
  line-height: 20px;
  padding: 8px 16px;
  font-family: mainFontFamily;
}
.forgotPassword {
  margin: 26px 0 36px;
}
.forgotPassword p {
  font-weight: 200;
  font-size: 14px;
  margin: 0;
}
.forgotPassword a {
  color: forgot-pass-blue;
  border-bottom: 1px solid forgot-pass-blue;
  padding-bottom: 2px;
  font-weight: 400;
}
.loginLoading svg {
  height: 20px;
  width: 20px;
}
.loginLoading svg path , .loginLoading svg path {
  stroke: white;
}
.footer {
  text-align: center;
  padding: 0 16px 69px;
  color: silver;
}
.requestSubmit {
  margin-top: 35px;
}
.validationWrapper {
  margin: 35px 0;
}
.region {
  display: flex;
  flex-direction: row;
}
.region svg {
  width: 18px;
  height: 18px;
  margin-right: 8px;
}
.selectedRegion {
  composes: region;
}
