@value silver, white, lighter-grey, light-grey from '../../../../Assets/styles/variables/colors.css';
@value mainFontFamily from '../../../../Assets/styles/variables/global.css';

.tableActionContainer {
  z-index: 13;
  position: absolute;
  top: -50px;
  left: 0;
  width: 100%;
  visibility: hidden;
  animation: fadeOutDown 250ms 0ms linear forwards;
  transition: visibility 0ms 250ms;
}

.tableActionContainer.isVisible {
  visibility: visible;
  animation: fadeInUp 250ms 0ms linear forwards;
  transition: visibility 250ms 0ms;
}

.tableAction {
  background-color: #4db6ac;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.24);
  height: 51px;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  position: relative;
  opacity: 0;
  animation: fadeOut 250ms 0ms linear forwards;
  text-align: left;
}

.tableActionContainer.isVisible .tableAction {
  animation: fadeIn 250ms 0ms linear forwards;
}

/* .tableAction::after {
  position: absolute;
  bottom: -8px;
  left: 19px;
  display: inline-block;
  content: '';
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-top: 8px solid #4db6ac;
} */

.tableActionContainer.isVisible .step1 {
  height: 100%;
  visibility: visible;
  opacity: 1;
}
.tableActionContainer.isVisible .step2 {
  height: 0%;
  visibility: hidden;
  opacity: 0;
}

.step1,
.step2 {
  opacity: 0;
  visibility: hidden;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: left;
}

.step2 {
  color: white;
  font-size: 16px;
  font-weight: 600;
  font-family: mainFontFamily;
}

.step2 span {
  font-weight: 700;
  padding-right: 4px;
}

.tableActionContainer.isVisible.isComplete .step1 {
  height: 0%;
  order: 2;
  animation: fadeOut 250ms 0ms linear forwards;
  visibility: hidden;
}

.tableActionContainer.isVisible.isComplete .step2 {
  height: 100%;
  order: 1;
  animation: fadeIn 250ms 0ms linear forwards;
  visibility: visible;
  justify-content: center;
}

.selection {
  padding-left: 12px;
  font-family: mainFontFamily;
  color: white;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  justify-content: center;
  min-width: 94px;
}

.actions {
  padding-left: 28px;
  display: flex;
  flex-direction: row;
}

.actions > *:not(:first-child) {
  margin-left: 16px;
}

.actionButtonWithIcon,
.actionButton {
  /* background-color: #e0f2f1; */
  background-color: #fff;
  /* box-shadow: 0px 1px 4px 0px rgba(0, 0, 0, 0.2); */
  border: 1px solid #142a39;
  cursor: pointer;
  height: 40px;
  min-width: 92px;
  font-family: SF Pro Display;
  font-size: 14px;
  font-weight: bold;
  color: #142a39;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 0px 8px;
}

.actionButton svg {
  height: 16px;
  width: 16px;
}

.actionButton:hover {
  background-color: #fff;
}

.actionButton:disabled,
.actionButton:disabled:hover {
  background-color: #fff;
  border: 1px dashed #babfbd;
  color: #babfbd;
  opacity: 0.5;
  cursor: not-allowed;
}

.actionButtonWithIcon span {
  padding-left: 8px;
}

.clearAction {
  border: none;
  background-color: transparent;
  position: absolute;
  right: 20px;
  padding: 0;
  cursor: pointer;
  top: 50%;
  transform: translateY(-50%);
}

.clearAction svg {
  height: 16px;
  width: 16px;
  stroke: white;
  fill: white;
}

.clearAction:disabled {
  cursor: not-allowed;
}

.clearAction:hover svg {
  stroke: light-grey;
  fill: light-grey;
  opacity: 1;
}

.submitLoading {
  composes: actionButton;
  padding: 3px 0;
}

.submitLoading svg {
  height: 20px;
  width: 20px;
}

.submitLoading:disabled,
.submitLoading:disabled:hover {
  stroke: silver;
  opacity: 1;
  background-color: lighter-grey;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(18px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOutDown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  100% {
    opacity: 0;
    transform: translateY(18px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
